import React, { useState, useEffect, useRef } from 'react';
import 'flowbite';

const AudioRecorder = () => {
    const [recording, setRecording] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    const [audioInputs, setAudioInputs] = useState([]);
    const [selectedAudioInput, setSelectedAudioInput] = useState('');
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    useEffect(() => {
        // Enumerate devices and filter out audio input devices
        const getAudioInputs = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioDevices = devices.filter(device => device.kind === 'audioinput');
            setAudioInputs(audioDevices);
            if (audioDevices.length > 0) {
                setSelectedAudioInput(audioDevices[0].deviceId);
            }
        };

        getAudioInputs();
    }, []);

    const startRecording = async () => {
        audioChunksRef.current = [];
        const constraints = { 
            audio: { deviceId: selectedAudioInput ? { exact: selectedAudioInput } : undefined }
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
            setAudioURL(URL.createObjectURL(audioBlob));
        };
        mediaRecorderRef.current.start();
        setRecording(true);
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setRecording(false);
    };

    const downloadRecording = () => {
        const link = document.createElement('a');
        link.href = audioURL;
        link.download = 'recording.webm';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
      <div className="container mx-auto p-4">
          <div className="flex flex-col items-center">
              <div className="mb-3 xl:w-96">
                  <select 
                      className="form-select appearance-none
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      value={selectedAudioInput}
                      onChange={(e) => setSelectedAudioInput(e.target.value)}
                      disabled={recording}
                  >
                      {audioInputs.map(input => (
                          <option key={input.deviceId} value={input.deviceId}>
                              {input.label || `Microphone ${input.deviceId}`}
                          </option>
                      ))}
                  </select>
              </div>
              <div className="flex space-x-2 justify-center">
                  <button 
                      className="px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                      onClick={startRecording} 
                      disabled={recording}
                  >
                      Start Recording
                  </button>
                  <button 
                      className="px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                      onClick={stopRecording} 
                      disabled={!recording}
                  >
                      Stop Recording
                  </button>
                  <button 
                      className="px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                      onClick={downloadRecording} 
                      disabled={!audioURL}
                  >
                      Download
                  </button>
              </div>
              {audioURL && <audio className="mt-4" src={audioURL} controls />}
          </div>
      </div>
  );
};


export default AudioRecorder;
