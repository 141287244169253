import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import ConfirmationModal from './ConfirmationModal'; // Import the ConfirmationModal component

const UserAccountEdit = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    role: '',
    generateNewPassword: false,
  });
  const [roles, setRoles] = useState([]);
  const [newPassword, setNewPassword] = useState('');
  const token = localStorage.getItem('token');
  const [showModal, setShowModal] = useState(false); // State for showing the confirmation modal

  useEffect(() => {
    // Fetch user data and roles
    fetch(`http://localhost:3001/get-user/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => response.json())
    .then(userData => {
      // Fetch roles
      fetch('http://localhost:3001/roles', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => response.json())
      .then(rolesData => {
        setRoles(rolesData);
        // Find the role ID that matches the user's role name
        const userRole = rolesData.find(role => role.name === userData.role);
        setFormData({
          firstName: userData.firstName,
          lastName: userData.lastName,
          emailAddress: userData.emailAddress,
          role: userRole ? userRole.id : '', // Set the role ID here
          generateNewPassword: false,
        });
      })
      .catch(error => console.error('Error fetching roles:', error));
    })
    .catch(error => console.error('Error fetching user data:', error));
  }, [userId, token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    fetch(`http://localhost:3001/edit-user/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log('User updated successfully:', data);
      if (data.newPassword) {
        setNewPassword(data.newPassword);
      }
    })
    .catch(error => {
      console.error('Error updating user:', error);
    });
  };

  const handleDelete = () => {
    // Show the confirmation modal when Delete is clicked
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    // Perform the delete action here
    fetch(`http://localhost:3001/delete-user/${userId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(() => {
      console.log('User deleted successfully');
      navigate('/user-list'); // Redirect after successful deletion
    })
    .catch(error => {
      console.error('Error deleting user:', error);
    });

    // Close the modal
    setShowModal(false);
  };

  const handleCancelDelete = () => {
    // Cancel the delete action here (or do nothing)
    setShowModal(false);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 min-h-screen p-5">
        <h2 className="text-2xl font-semibold mb-4">Edit User Account</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="firstName" className="block font-medium">First Name:</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lastName" className="block font-medium">Last Name:</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="eAddress" className="block font-medium">Mail Address:</label>
            <input
              type="email"
              id="emailAddress"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={(e) => setFormData({ ...formData, emailAddress: e.target.value })}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="role" className="block font-medium">Role:</label>
            <select
              id="role"
              name="role"
              value={formData.role}
              onChange={(e) => setFormData({ ...formData, role: e.target.value })}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
            >
              <option value="">Select a role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>{role.name}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <button
              type="button"
              onClick={() =>
                setFormData({
                  ...formData,
                  generateNewPassword: !formData.generateNewPassword,
                })
              }
              className={`py-2 px-4 text-white rounded-md ${
                formData.generateNewPassword ? 'bg-gray-500' : 'bg-blue-500 hover:bg-blue-600'
              }`}
            >
              {formData.generateNewPassword ? 'New Password Generated' : 'Generate New Password'}
            </button>
          </div>
          <button type="submit" className="mt-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            Save Changes
          </button>
        </form>
        <div className="mt-4 flex justify-between items-center">
        <button
          className="py-2 px-4 bg-red-500 hover:bg-red-600 text-white rounded-md"
          onClick={handleDelete} // Ensure that the event handler is set correctly here
        >
          Delete User
        </button>
        {newPassword && (
          <div className="text-green-600">
            New Password: {newPassword}
          </div>
        )}
      </div>
      {/* Render the modal when show modal is true */}
      {showModal && (
        <ConfirmationModal
          message="Are you sure you want to delete this user?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
    </div>
  );
};

export default UserAccountEdit;
