// HomeRedirect.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const HomeRedirect = () => {
  const isAuthenticated = () => {
    // Implement your authentication logic here
    const token = localStorage.getItem('token');
    return !!token;
  };

  if (isAuthenticated()) {
    // If logged in, redirect to overview
    return <Navigate to="/overview" />;
  } else {
    // If not logged in, redirect to login
    return <Navigate to="/login" />;
  }
};

export default HomeRedirect;
