import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch users
    fetch('http://localhost:3001/get-all-users', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      setUsers(data);
    })
    .catch(error => {
      console.error('Error fetching users:', error);
    });

    // Fetch roles
    fetch('http://localhost:3001/roles', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => response.json())
    .then(data => {
      setRoles(data);
    })
    .catch(error => console.error('Error fetching roles:', error));
  }, []);

  const handleEdit = (userId) => {
    navigate(`/edit-account/${userId}`);
  };

  const handleAddAccount = () => {
    navigate('/create-account');
  };

  const filteredUsers = users.filter(user =>
    (user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || 
     user.lastName.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (selectedRole === '' || user.role.toLowerCase() === selectedRole.toLowerCase())
  );
  

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const usersForCurrentPage = filteredUsers.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRoleFilterChange = (event) => {
    const selectedRoleName = event.target.value;
    setSelectedRole(selectedRoleName);
  };
  

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 bg-gray-100 dark:bg-gray-800 p-5 relative">
        <h2 className="text-2xl font-semibold mb-4">Users</h2>
        <button
          onClick={handleAddAccount}
          className="absolute top-5 right-5 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md"
        >
          Add Account
        </button>
        <div className="bg-white dark:bg-gray-900 shadow rounded p-4 mb-4">
          <div className="flex items-center space-x-4 mb-4">
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
         <select
  value={selectedRole}
  onChange={handleRoleFilterChange}
  className="border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
>
  <option value="">All Roles</option>
  {roles.map((role) => (
    <option key={role.id} value={role.name}>{role.name}</option>
  ))}
</select>


          </div>
          <ul>
            {usersForCurrentPage.map(user => (
              <li key={user.id} className="flex items-center justify-between border-b border-gray-200 pb-2 mb-2">
                <div>
                  <p className="text-lg font-semibold">{user.firstName + " " + user.lastName}</p>
                  <p>{user.role}</p>
                </div>
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                  onClick={() => handleEdit(user.id)}
                >
                  Edit
                </button>
              </li>
            ))}
          </ul>
          {totalPages > 1 && (
            <div className="flex justify-center mt-4">
              {Array.from({ length: totalPages }, (_, index) => (
                <button               key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`mx-1 px-3 py-1 rounded-md cursor-pointer ${
                  currentPage === index + 1
                    ? 'bg-blue-500 text-white'
                    : 'bg-white dark:bg-gray-900 text-blue-500'
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
);
};

export default UserList;  
