import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextInput } from 'flowbite-react';

const FlowbiteLogin = () => {
  const [email_address, setEmail] = useState(''); 
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ message: '', show: false });
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3001/login', { email_address, password });
  
      localStorage.setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('token', response.data.accessToken);
  
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
  
      navigate('/overview');
    } catch (error) {
      console.error(error);
      setError({ message: 'Login failed! Please check your credentials.', show: true });
    }
  };
  

  const closeErrorToast = () => setError({ ...error, show: false });

  return (
    <div className="h-screen w-full bg-gray-900">
      <div className="h-screen flex items-center">
        <div className="w-full max-w-sm mx-auto bg-gray-600 rounded shadow-xl shadow-indigo-500/40 p-6">
          <img src="/logo192.png" alt="Logo" className="w-16 h-16 mx-auto mb-2" />
          <h2 className="text-xl font-bold text-white text-center mb-0">Login</h2>
          <h3 className="text-sm font-bold text-white text-center mb-6">ADS</h3>
          <form onSubmit={handleLogin} className="flex flex-col gap-4">
            <div>
              <TextInput
                id="email"
                type="email" // Change to email type
                value={email_address}
                onChange={(e) => setEmail(e.target.value)} 
                placeholder="Email Address" 
                required
                autoComplete="email" 
                className="w-full mt-1 text-lg font-bold text-gray-300"
              />
            </div>
            <div>
              <TextInput
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
                autoComplete="current-password"
                className="w-full mt-1 text-lg font-bold text-gray-300"
              />
            </div>
            <button type="submit" className="mt-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
              Login
            </button>
          </form>
        </div>
      </div>
      {error.show && (
        <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-4">
          <div id="toast-warning" className="flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
              </svg>
              <span className="sr-only">Warning icon</span>
            </div>
            <div className="ms-3 text-sm font-normal">{error.message}</div>
            <button onClick={closeErrorToast} type="button" className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-warning" aria-label="Close">
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlowbiteLogin;
