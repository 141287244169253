import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';

const Overview = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get('http://localhost:3001/user-details', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setUserDetails(response.data);
      } catch (error) {
        console.error(error);
        navigate('/login');
      }
    };

    fetchUserDetails();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 bg-gray-100 dark:bg-gray-800 p-5">
        <h2 className="text-2xl font-semibold mb-4">Overview Page</h2>
        <div className="bg-white dark:bg-gray-900 shadow rounded p-4 mb-4">
        <p className="text-lg font-semibold">Welcome, {userDetails.firstName}!</p>
          <p>First Name: {userDetails.firstName}</p>
          <p>Last Name: {userDetails.lastName}</p>
          <p>Email: {userDetails.emailAddress}</p>
          <p>Your role: {userDetails.role}</p>
        </div>

        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
          onClick={handleLogout}
        >
          Logout
        </button>

        {/* Render role-specific content based on the user's role */}
        {userDetails.role === 'Admin' && (
          <div className="mt-4 bg-white dark:bg-gray-900 shadow rounded p-4">
            <p className="text-lg font-semibold">This is the Admin section. You have full access.</p>
          </div>
        )}

        {userDetails.role === 'CopyWriter' && (
          <div className="mt-4 bg-white dark:bg-gray-900 shadow rounded p-4">
            <p className="text-lg font-semibold">This is the CopyWriter section. You have access to CopyWriter content.</p>
          </div>
        )}

        {userDetails.role === 'VoiceOver' && (
          <div className="mt-4 bg-white dark:bg-gray-900 shadow rounded p-4">
            <p className="text-lg font-semibold">This is the VoiceOver section. You have access to VoiceOver content.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Overview;
