import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Sidebar } from 'flowbite-react';
import { HiChartPie, HiViewBoards, HiUser, HiInbox } from 'react-icons/hi';

const containerStyle = {
  display: 'flex',
  height: '100vh', // Make the container full viewport height
};

const sidebarStyle = {
  height: '100%', // Make the sidebar full height
  background: '#333', // Dark background color
  color: '#fff', // Text color
};

function SidebarComponent() {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        // handle no token scenario
        return;
      }

      try {
        const response = await axios.get('http://localhost:3001/user-details', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setUserRole(response.data.role); // Assuming the role is directly on the data object
      } catch (error) {
        console.error(error);
        // handle error scenario
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <div style={containerStyle}>
      <Sidebar aria-label="Sidebar with content separator example" style={sidebarStyle}>
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            <Sidebar.Item href="/">
              <span>{<HiChartPie />}</span> Dashboard
            </Sidebar.Item>
            <Sidebar.Item href="/projects">
              <span>{<HiViewBoards />}</span> Projects
            </Sidebar.Item>
          </Sidebar.ItemGroup>
          {userRole === 'Admin' && (
            <Sidebar.ItemGroup>
              <Sidebar.Item href="/user-list">
                <span>{<HiUser />}</span> Users
              </Sidebar.Item>
            </Sidebar.ItemGroup>
          )}
          <Sidebar.ItemGroup>
            <Sidebar.Item href="/settings">
              <span>{<HiInbox />}</span> Settings
            </Sidebar.Item>
            {/* Other items can be added here as needed */}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  );
}

export default SidebarComponent;
