import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import { FileInput } from 'flowbite-react';

const AddProject = () => {
    const [projectName, setProjectName] = useState('');
    const [duration, setDuration] = useState('');
    const [frameRate, setFrameRate] = useState('');
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [conversionProgress, setConversionProgress] = useState(0);
    const [isConverting, setIsConverting] = useState(false);
    const [selectedVoiceUserId, setSelectedVoiceUserId] = useState('');
    const [selectedMVO1UserId, setSelectedMVO1UserId] = useState('');
    const [selectedMVO2UserId, setSelectedMVO2UserId] = useState('');
    const [selectedFVO1UserId, setSelectedFVO1UserId] = useState('');
    const [selectedFVO2UserId, setSelectedFVO2UserId] = useState('');
    const [selectedCopyWriterUserId, setSelectedCopyWriterUserId] = useState('');
    const [selectedAudioProducerUserId, setSelectedAudioProducerUserId] = useState('');
    const [userList, setUserList] = useState([]); // State to store the user list

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (!projectName) {
      const fileNameWithoutExt = file.name.replace(/\.[^/.]+$/, '');
      setProjectName(fileNameWithoutExt);
    }
  };

  useEffect(() => {
    const eventSource = new EventSource('http://localhost:3001/conversion-progress');

    eventSource.onopen = () => {
      console.log('SSE connection opened');
    };

    eventSource.onerror = (error) => {
      console.error('SSE error:', error);
    };

    eventSource.onmessage = (e) => {
      const data = JSON.parse(e.data);
      console.log('Received data:', data);

      if (data.event === 'conversionProgress') {
        setConversionProgress(data.data);

        if (!isConverting && data.data >= 0 && data.data < 100) {
          setIsConverting(true);
        }
      } else if (data.event === 'conversionComplete') {
        setIsConverting(false);
      }
    };

    return () => {
      eventSource.close();
      console.log('SSE connection closed');
    };
  }, []);

  useEffect(() => {
    // Fetch the user list from your existing user list endpoint
    axios
      .get('http://localhost:3001/get-all-users', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        setUserList(response.data);
      })
      .catch((error) => {
        console.error('Error fetching user list:', error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('video', file);
    formData.append('title', projectName);
    // formData.append('userId', selectedUserId); // Add the selected user ID to the form data
    try {
      setIsUploading(true);
      const response = await axios.post('http://localhost:3001/upload-video', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      if (response.status === 200) {
        console.log('Upload successful');
        setIsUploading(false);
        setDuration(response.data.duration);
        setFrameRate(response.data.frameRate);
      }
    } catch (error) {
      console.error('Upload failed:', error);
      setIsUploading(false);
    }
  };

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="container mx-auto p-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Project Name</label>
            <input
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Video File</label>
            <FileInput
              id="video"
              helperText="Drag and drop a file or select add file"
              onChange={handleFileChange}
              accept="video/*"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Voice</label>
            <select
              value={selectedVoiceUserId}
              onChange={(e) => setSelectedVoiceUserId(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select User</option>
              {userList
                .filter((user) => user.role === 'VoiceOver')
                .map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">MVO1</label>
            <select
              value={selectedMVO1UserId}
              onChange={(e) => setSelectedMVO1UserId(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select User</option>
              {userList
                .filter((user) => user.role === 'VoiceOver')
                .map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">MVO2</label>
            <select
              value={selectedMVO2UserId}
              onChange={(e) => setSelectedMVO2UserId(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select User</option>
              {userList
                .filter((user) => user.role === 'VoiceOver')
                .map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">FVO1</label>
            <select
              value={selectedFVO1UserId}
              onChange={(e) => setSelectedFVO1UserId(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select User</option>
              {userList
                .filter((user) => user.role === 'VoiceOver')
                .map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">FVO2</label>
            <select
              value={selectedFVO2UserId}
              onChange={(e) => setSelectedFVO2UserId(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select User</option>
              {userList
                .filter((user) => user.role === 'VoiceOver')
                .map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
            </select>
          </div>
        
          <div>
            <label className="block text-sm font-medium text-gray-700">Copy Writer</label>
            <select
              value={selectedCopyWriterUserId}
              onChange={(e) => setSelectedCopyWriterUserId(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select User</option>
              {userList
                .filter((user) => user.role === 'CopyWriter')
                .map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Audio Producer</label>
            <select
              value={selectedAudioProducerUserId}
              onChange={(e) => setSelectedAudioProducerUserId(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select User</option>
              {userList
                .filter((user) => user.role === 'AudioProducer')
                .map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <button
              className={`mt-2 px-4 py-2 text-white bg-blue-500 rounded-md ${isUploading ? 'bg-blue-300' : ''}`}
              disabled={isUploading}
            >
              Upload
            </button>
          </div>
          {uploadProgress > 0 && (
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700">Upload Progress</label>
              <progress className="w-full" value={uploadProgress} max="100"></progress>
              <span className="text-sm ml-2">{uploadProgress}%</span>
              {uploadProgress === 100 && <span className="text-green-500 ml-2">✔️ Upload Finished</span>}
            </div>
          )}
          {conversionProgress > 0 && (
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700">Conversion Progress</label>
              <progress className="w-full" value={conversionProgress} max="100"></progress>
              <span className="text-sm ml-2">{conversionProgress}%</span>
              {conversionProgress === 100 && <span className="text-green-500 ml-2">✔️ Conversion Finished</span>}
            </div>
          )}
          {duration && (
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700">Video Duration: {duration} seconds</label>
            </div>
          )}
          {frameRate && (
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700">Frame Rate: {frameRate}</label>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddProject;
