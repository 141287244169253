import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Overview from './components/Overview';
import UserAccountCreate from './components/UserAccountCreate';
import UserAccountEdit from './components/UserAccountEdit';
import UserList from './components/UserList';
import NotFound from './components/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import HomeRedirect from './components/HomeRedirect'; 
import AddProject from './components/AddProject';
import AudioRecorder from './components/AudioRecorder';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeRedirect />} />
        <Route path="/login" element={<Login />} />
        <Route path="/overview" element={<ProtectedRoute><Overview /></ProtectedRoute>} />
        <Route path="/create-account" element={<ProtectedRoute><UserAccountCreate /></ProtectedRoute>} />
        <Route path="/user-list" element={<ProtectedRoute requiredRole="Admin"><UserList /></ProtectedRoute>} />
        <Route path="/edit-account/:userId" element={<ProtectedRoute><UserAccountEdit /></ProtectedRoute>} />
        <Route path="/add-project" element={<ProtectedRoute><AddProject /></ProtectedRoute>} />
        <Route path="/record-audio" element={<AudioRecorder />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
