import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar'; // Import the Sidebar component

const UserAccountCreate = () => {
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setMailAddress] = useState('');
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]); // State for roles
  const [generatedPassword, setGeneratedPassword] = useState('');

  useEffect(() => {
    // Fetch roles
    const fetchRoles = async () => {
      try {
        const response = await axios.get('http://localhost:3001/roles', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setRoles(response.data);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('You are not logged in.');
        return;
      }

      const response = await axios.post('http://localhost:3001/register', {
        password,
        firstName,
        lastName,
        emailAddress,
        role, // This is now the role ID
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      setGeneratedPassword(response.data.password);
      alert('User created successfully!');
    } catch (error) {
      alert('Failed to create user.');
      console.error('Error creating user:', error);
    }
  };


  return (
    <div className="flex">
      <Sidebar /> {/* Render the Sidebar component */}
      <div className="flex-1 min-h-screen p-5">
        <h2 className="text-2xl font-semibold mb-4">Create User Account</h2>
        <form onSubmit={handleCreateUser}>
          <div className="mb-4">
            <label htmlFor="firstName" className="block font-medium">First Name:</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lastName" className="block font-medium">Last Name:</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mailAddress" className="block font-medium">Mail Address:</label>
            <input
              type="email"
              value={emailAddress}
              onChange={(e) => setMailAddress(e.target.value)}
              required
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="role" className="block font-medium">User Role:</label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
            >
              <option value="">Select a role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>{role.name}</option>
              ))}
            </select>
          </div>
          <button
            type="submit"
            className="py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md"
          >
            Create User
          </button>
          {generatedPassword && (
            <div className="mt-4">
              <p className="font-medium text-green-600">
                Generated Password: {generatedPassword}
              </p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default UserAccountCreate;
