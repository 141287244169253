import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = ({ children, requiredRole }) => {
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // const isAuthenticated = () => {
  //   const token = localStorage.getItem('token');
  //   return !!token;
  // };

  useEffect(() => {
    if (!requiredRole) {
      // If no specific role is required, consider the route accessible
      setIsLoading(false);
      return;
    }
  
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      setIsLoading(false);
      return;
    }
  
    const fetchUserRole = async () => {
      try {
        const response = await axios.get('http://localhost:3001/user-details', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setUserRole(response.data.role); // Adjust based on your API response
      } catch (error) {
        console.error(error);
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchUserRole();
  }, [navigate, requiredRole]);
  

  if (isLoading) {
    return <div>Loading...</div>; // or any other loading state representation
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/overview" />;
  }

  return children;
};

export default ProtectedRoute;
